:root {
  --primary-color: #1f2188;
  --primary-light-color: #4647c7;
  --color-1st: #3366ff;

  --light-color: #f6fbff;
  --yellow-color: #ffcc33;
  --green-color: #48b58a;
  --red-color: #ee837e;
  --gray-light-color: #e8edfb;
  --gray-color: #e8edfb;
  --text-primary-color: #1b1b2f;
  --text-secondray-colot: #74808b;
  --text-color: #465766;
}

.bt-primary {
  background-color: var(--primary-color);
  border-radius: 10px;
  height: 32px;
  line-height: 32px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  gap: 5px;
  min-width: 100px;
  &:hover:enabled {
    background-color: #036cc2;
  }
}

.bt-danger {
  background-color: #fde9e8;
  border-radius: 10px;
  height: 32px;
  width: 32px !important;
  line-height: 32px;
  color: var(--red-color);
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  gap: 5px;
}

.bt-save {
  background-color: var(--green-color);
  border-radius: 10px;
  height: 32px;
  line-height: 32px;
  color: white;
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  gap: 5px;
  min-width: 100px;
}

.bt-light {
  background-color: #eff8ff;
  border-radius: 10px;
  height: 50px;
  line-height: 50px;
  color: var(--text-primary-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  &:hover:enabled {
    background-color: #e6f0f9;
  }
}

.bt-light-dark {
  background-color: #cee9fd;
  border-radius: 10px;
  height: 40px;
  font-weight: 600;
  line-height: 50px;
  color: var(--text-primary-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  &:hover:enabled {
    background-color: #e6f0f9;
  }
}

.h-32 {
  height: 32px !important;
  line-height: 32px !important;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  height: 45px;
  width: 100%;
  color: #001b2e;
  font-weight: 600;
  border: 1px solid var(--gray-light-color);
  background: var(--gray-light-color);
  transition: 0.5s ease-in-out;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  &:hover,
  &:focus {
    background: #c2d4e8;
    border: 1px solid #dde8f4;
    color: #001b2e;
  }
}

.ant-btn-dangerous.ant-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  height: 45px;
  width: 100%;
  color: #fff;
  font-weight: 600;
  border: none;
  background: #e43f5a;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
  &:hover,
  &:focus {
    background: rgb(170, 41, 34);
    color: #fff;
  }
}

.filter-template {
  color: var(--primary-color);
}

.clear {
  cursor: pointer;
  color: #e43f5a;
}

.fit-content {
  width: fit-content !important;
}

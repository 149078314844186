.dash-container {
  padding: 2% !important;

  max-width: 1920px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-primary-color);

    span {
      color: var(--text-secondray-colot);
      font-weight: 400 !important;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.card-setting {
  background-color: white;
  border: 1px solid var(--gray-light-color);
  border-radius: 20px;
  padding: 20px;
  .filter-box {
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;

    &.br-bottom {
      padding-bottom: 15px !important;
      border-bottom: 1px solid var(--gray-color) !important;
    }
  }
}
.filter-box {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid var(--gray-light-color);
  border-radius: 20px;
  padding: 20px;
  & > h1 {
    flex: 1;
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & > h2 {
    flex: 1;
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: #253866;
    font-weight: 500 !important;
    i {
      color: var(--primary-color);
      font-size: 22px !important;
    }
  }
  .filter-right {
    display: flex;
    gap: 10px;
  }

  button {
    border-radius: 8px !important;
    padding: 1rem;
    &.bt-primary {
      width: 32px !important;
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 8px !important;
  }
}

.card-view {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--gray-light-color);
}

.ant-table-thead > tr > th {
  background-color: #eff8ff;
}

[title='supprimer'] {
  color: red;
}

.icons {
  i {
    font-size: 18px !important;
  }
  .edit {
    color: var(--primary-color);
  }

  .list {
    color: var(--text-primary-color);
    font-size: 18px;
  }
}
.delete {
  color: #e43f5a;
}
.bg-red {
  color: #e43f5a;
}

img,
svg {
  vertical-align: unset !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  display: flex;
  width: 100%;
}
.ant-modal-close-icon {
  background: #ff000094;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-drawer-header {
  color: var(--text-primary-color) !important;
}
tbody > tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.ant-upload.ant-upload-drag {
  background-color: var(--light-color) !important;
  border-radius: 10px !important;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  i {
    font-size: 91px !important;
  }
  p {
    color: var(--text-secondray-colot);
  }
  width: 100%;
  padding: 20px;
}

.ant-skeleton-element .ant-skeleton-button,
.ant-skeleton-element .ant-skeleton-image {
  width: 100% !important;
}

.row {
  --bs-gutter-y: 1rem !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 10px 15px !important;
}

.ant-dropdown-menu {
  border-radius: 8px !important;
}

.hide-show {
  background-color: var(--primary-color);
  border-radius: 10px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}

.balance-card {
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--gray-light-color);
  .info {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 16px;
    }
    span:first-child {
      font-weight: 500;
    }
  }
}

.ant-popover-content {
  min-width: 380px !important;
}
.ant-popover-inner {
  border-radius: 10px;
}

.ant-popover-title {
  min-height: 52px !important;
  display: flex;
  align-items: center;
}

.ant-alert-info {
  border-radius: 10px !important;
}
.show {
  display: none !important;
}

.card-view.emt {
  padding: 15px 20px !important;
}

.card-view {
  height: 100%;
  .header-card {
    font-size: 18px;
    font-weight: 600;

    margin-bottom: 10px;
    span {
      color: #8293ae !important;
    }
  }
  .lists-row {
    display: flex;
    width: 100%;
    gap: 10px;
    min-height: 180px;
    .card-nfc {
      .icon {
        font-size: 70px;
      }
      flex: 1;
      border-radius: 10px;
      padding: 20px;

      background-color: #f7f9fd;
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 15px;
      }
      span {
        margin-top: auto !important;
        color: #8293ae;
        font-size: 14px !important;
      }
      h2 {
        font-size: 28px !important;
        font-weight: 700 !important;
        margin: 0 !important;
      }
    }
  }
  .lists {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .indicator {
      background-color: #f7f9fd;
      border-radius: 10px;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        color: #8293ae;
        font-size: 14px;
        font-weight: 500;

        &.number {
          font-size: 14px;
          font-weight: 700;
          color: #253866;
        }
      }
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

@media screen and (max-width: 720px) {
  .dash-container {
    padding: 2% !important;
  }
  .auth > img {
    width: 200px !important;
  }

  .ant-popover-content {
    min-width: 280px !important;
  }

  .imgs {
    justify-content: center !important;
    margin-bottom: 10px;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain !important;
      aspect-ratio: 2/3;
    }
  }
  .auth-container {
    min-width: 98% !important;
    padding: 2.5rem 1rem !important;
  }
  .graph-right {
    display: none !important;
  }
  .header {
    padding: 10px 1rem;
  }
  .trip-data {
    width: 100% !important;
  }

  .filter-box {
    flex-direction: column;
    h1 {
      margin-bottom: 15px !important;
      span {
        display: none;
      }
    }
    .filter-right {
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    .ant-input-affix-wrapper {
      width: 100% !important;
    }
  }

  .header-card .view-s {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .travel-history-card .card-heading {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    & > * {
      flex: 1;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1180px) {
  .hide {
    display: none !important;

  }
  .show {
    display: flex !important;
    align-items: center !important;
  }

  .left.show {
    gap: 20px;
    i {
      background-color: var(--gray-light-color);
      padding: 5px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      color: var(--primary-color);
    }
  }

  .header-dashboard,
  .form-row,
  .header-card,
  .filter-box,
  .right {
    flex-wrap: wrap;
  }

  .sidebar .ant-drawer-body {
    padding: 0 !important;
  }

  .card-plastic-container {
    overflow: auto;
  }

  .table-mat {
    overflow: auto;
    td,
    th {
      min-width: 160px !important;
      white-space: nowrap;
    }
  }
}

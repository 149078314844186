.auth-wrapper {
  background-color: var(--light-color);
  min-height: 100vh;
  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    img {
      object-fit: contain;
    }
    .auth-container {
      z-index: 1;
      position: relative;
      background-color: #fff;
      border: 1px solid var(--gray-color);
      border-radius: 50px;
      padding: 2.5rem 3rem;
      display: flex;
      flex-direction: column;
      min-width: 520px;

      .auth-top {
        h1 {
          color: var(--text-primary-color);
          font-size: 26px !important;
          font-weight: 700;
          margin: 0 !important;
        }
        p {
          color: #74808b;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .graph-left {
        position: absolute;
        left: -97px;
        top: 25%;
        z-index: 0;
      }

      .graph-right {
        position: absolute;
        right: -60px;
        top: 10%;
        z-index: 0;
      }
    }
  }
}
.form-row {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  & > * {
    flex: 1;
  }
}
.ant-form-item {
  margin: 0 0 15px !important;
}
.form-group {
  display: flex;
  flex-direction: column;
  label[title="required"] {
    &::after {
      content: "*";
      font-size: 16px;
      display: inline-block;
      color: var(--red-color);
    }
  }
  .form-section {
    display: flex;
    align-items: center;

    border: 1px solid var(--gray-color);
    border-radius: 10px;
    height: 50px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 100%;
      background-color: #f9faff;
      border-right: 1px solid var(--gray-color);
      border-radius: 10px 0 0 10px;
    }
    input {
      padding: 0 10px !important;
      all: unset;
      width: 100%;
      height: 100%;
    }
  }
}

.note {
  span {
    color: var(--primary-color);
  }
}

.forgot {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.ant-drawer-wrapper-body {
  position: relative;
  .footer-drawer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 24px;

    background-color: white;
    border-top: 1px solid var(--gray-color);
    .form-wrapper {
      margin-bottom: 2rem;
    }
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
 
  padding: 0px;
  //height: 100%;
}

.form-wrapper-second {
  h2 {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-color);
    margin-bottom: 10px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #aab3cd;
      font-style: italic;
    }
  }
}

.ant-picker {
  border-radius: 10px !important;
  background-color: #f9faff;
  border: 1px solid var(--gray-color);
  height: 40px;
  width: 100%;
}

.form-wrapper {
  button {
    height: 40px !important;
    padding: 0 2rem;
  }

  .ant-input,
  .ant-select {
    border-radius: 10px !important;
    background-color: #f9faff;
    border: 1px solid var(--gray-color);
    height: 40px;
  }

  .ant-select {
    .ant-select-selector {
      border: none !important;
      outline: none !important;
      background-color: transparent !important;
      //display: block;

      padding: 4px 10px;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 10px !important;
    background-color: #f9faff;
    border: 1px solid var(--gray-color);
    height: 40px;
    .ant-input,
    .ant-select {
      height: 100% !important;
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: var(--primary-color) !important;
    border-radius: 5px;
    border: none !important;
    color: #fff !important;
  }

  .ant-select-multiple .ant-select-selection-item-remove {
    color: #fff !important;
  }
}

.search-box {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
    min-width: 200px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.ant-input-affix-wrapper > input.ant-input {
  border-radius: 0 !important;
}
@media screen and (max-width: 768px) {

  .form-row {
   gap: 0 !important;
  }
  .form-group {
   min-width: 100% !important;
  }
 }
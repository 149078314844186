aside {
  display: flex;
  flex-direction: column;
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--primary-color);
  min-width: 270px;
  color: #253866;
  box-shadow: var(--shadow);
  height: 100vh;
  border-right: 1px solid var(--gray-light-color);
  transition: width 0.3s ease-in-out;
  padding-top: 1.5rem;

  .logo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 65px;
    margin: 0 1rem;
  }
  &.sidebar {
    .toggle-side {
      position: absolute;
      background-color: white;
      right: -17px;
      top: 120px;
      border: 1px solid var(--border-color);
      border-radius: 30px;
      width: 35px;
      height: 35px;
      box-shadow: var(--shadow);
      line-height: 35px;
      text-align: center;
      transition: ease-in-out 0.3s;
      &:hover {
        background-color: var(--color-link);
        color: #fff;
      }
    }
  }
  .logo {
    .logo-bg {
      background-color: var(--color-bg);
      border-radius: 8px;
      padding: 1rem 2rem;
      h1 {
        font-weight: bold;
        margin: 0 !important;
        color: var(--color-1st) !important;
        font-size: min(22px, calc(2vw + 15px));
      }
    }
  }

  &.small {
    min-width: 80px !important;
    width: 80px !important;
    padding: 15px 8px;

    .logo .logo-bg {
      background-color: var(--color-bg);
      border-radius: 8px;
      padding: 0.5rem 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    .small-menu ul li > div > a {
      padding: 15px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 20px;
        font-weight: 600 !important;
      }
    }
    /*  & > *:not(:first-child) {
        display: none !important;
      } */
  }
}

.menu.small-menu li > a,
.menu.small-menu li > div > a {
  padding-left: 15px !important;

  i {
    font-size: 20px;
    font-weight: 600 !important;
  }
}

a {
  text-decoration: none;
}

.menu {
  margin-top: 3rem;
  max-height: 100vh;

  ul {
    margin: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: calc(100vh - 13rem);
    transition: 0.2s ease-in-out;
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
    padding: 0 !important;
    list-style: none;
  }

  li {
    transition: 0.2s ease-in-out;
    i {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }

    ul {
      color: #fff;
      background-color: var(--primary-light-color);
      border-radius: 0px;
      transition: ease-in-out 0.3s;
      li > a {
        display: flex;
        align-items: center;

        padding: 11px 2rem !important;
        padding-left: 4rem !important;
        white-space: nowrap;
        transition: color 0.2s ease-in-out;
      }
    }

    & > .side-link > a {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      padding: 15px 2rem !important;
      padding-right: 1rem !important;
      white-space: nowrap;
      transition: color 0.2s ease-in-out;
      &.active {
        background-color: var(--primary-light-color) !important;
        color: #fff !important;
      }
      &:hover:not(.active) {
        background-color: transparent !important;
      }
    }
  }
  li a.active {
    color: #fff !important;
  }

  &.small-menu {
    height: calc(100% - 11.7rem);
    li {
      i {
        font-size: 24px;
        font-weight: 500;
      }
      & > a,
      & > div > a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #253866;
        font-weight: 600;

        transition: 0.2s ease-in-out;
      }

      & > div > a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #253866;
        font-weight: 600;
        font-size: 14px;
        padding: 15px;

        transition: 0.2s ease-in-out;
      }
      & > div {
        ul {
          padding-left: 0.5rem !important;
        }
      }
    }
    li a.active {
      color: var(--primary-color) !important;
    }
  }
}

div.side-link.active {
  position: relative;
  background-color: var(--primary-light-color) !important;

  &::before {
    content: '';
    width: 1px;
    height: calc(100% - 55px);
    display: block;
    background-color: #c5d4fe;
    position: absolute;
    top: 35px;
    left: 41px;
  }
}

.sub-link {
  position: relative;
  color: #253866 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  i {
    font-size: 16px !important;
  }
  &.active {
    color: var(--primary-color) !important;
    &::before {
      border-color: var(--primary-color) !important;
    }
  }
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 10px;
    border: 2px solid #c5d4fe;
    background-color: #eff8ff;
    position: absolute;
    top: 38%;
    left: 37px;
  }
}

aside.small.small-menu ul li > div > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-bottom {
  margin-top: auto;

  border-top: 1px solid var(--gray-light-color);
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 15px 2rem !important;
    white-space: nowrap;
    transition: color 0.2s ease-in-out;
    i {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.header {
  background-color: white;
  height: 70px;
  padding: 10px 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-light-color);
  .left {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #253866;
    i {
      font-size: 20px;
    }
    &:hover {
      color: var(--primary-color) !important;
    }
  }
  .link {
    color: #253866 !important;
    i {
      font-size: 20px;
    }
  }
  & > * {
    flex: 1;
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
  .nav-actions {
    display: flex;
    gap: 25px;

    a {
      font-size: 20px;
      i {
        font-size: 18px;
      }
    }
  }
  .user-info-navbar {
    img {
      object-fit: contain;
      aspect-ratio: 2/3;
      border-radius: 50%;
    }
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
      font-weight: 500;
    }
  }
}

.menu.menu-small {
  div.side-link.active,
  .sub-link {
    &::before {
      display: none !important;
    }
  }

  li ul li > a,
  ul li > div > a {
    padding: 15px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.side-bottom.sm {
  a {
    padding: 15px 1.3rem !important;
  }
}
